import React from 'react'
import { Wrap, Title, SectionHeader } from '../components/page/components'
import GlobalHeader from '../components/global/header'
import SEO from '../components/SEO'
import { Flex, Box } from 'reflexbox/styled-components'
import GlobalFooter from '../components/global/footer'
import FaqAccordions from '../components/faq/accordions'

const Faq = () => {
  return (
    <Wrap>
      <SEO title="FAQ" />
      <GlobalHeader hideLogo={true} />
      <SectionHeader>
        <Title>FAQ</Title>
      </SectionHeader>

      <Flex flexWrap="nowrap">
        <Box width={[1, 6 / 12]} ml={[1, `${(1 / 5) * 100}%`]}>
          <FaqAccordions />
        </Box>
      </Flex>
      <GlobalFooter />
    </Wrap>
  )
}

export default Faq
